<template>
  <b-card
    :class="$store.getters['layout/getInnerWidth'] > 900 ? classes : 'ml-0'"
  >
    <label
      class="comingsoon-title"
    >
      {{ $t('views.client.details.body.right.recent-bookings.coming') }}
    </label>
    <div
      class="row border-bottom-grey"
    />
    <div class="blog-details p-2">
      <template v-for="(booking, i) of bookings">
        <booking-details
          :key="'booking-details-' + i"
          :booking="booking"
        />
      </template>
      <template v-if="bookings.length === 0">
        <b-row class="mt-3 mb-2">
          <b-col class="text-block" align="center">
            {{ $t('views.client.details.body.right.recent-bookings.no-bookings') }}
          </b-col>
        </b-row>
      </template>
    </div>
  </b-card>
</template>

<script>
import Booking from "@/classes/doinsport/Booking";
import {YEAR_DASH_MONTH_DASH_DAY} from "@/utils/date";
import {getClubBookingsByClientId} from "@api/doinsport/services/bookings/booking.api";

export default {
  data: () => ({
    date: null,
    operator: null,
    currentPage: 1,
    canceled: false,
    itemsPerPage: 3,
    totalRows: 0,
    bookings: [],
    idClub: null,
  }),
  props: {
    classes: {
      type: String,
      default: 'ml-2'
    }
  },
  components: {
    BookingDetails: () => import('./BookingDetails'),
  },
  methods: {
    loadComingSoonBookings() {
      this.date = this.$toTimezoneCustomFormat({date: this.$moment.utc(), format: YEAR_DASH_MONTH_DASH_DAY});
      this.operator = 'after';
      this.canceled = false;

      getClubBookingsByClientId(this.$route.params.id, this.itemsPerPage, this.currentPage, '', this.canceled, this.date, this.operator)
        .then((response) => {
          this.bookings = [];
          this.totalRows = response.data['hydra:totalItems'];

          for (let item of response.data['hydra:member']) {
            this.bookings.push(new Booking(item));
          }
        })
        .finally(() => {
          this.isBusy = false;
        })
      ;
    },
  },
  created() {
    this.loadComingSoonBookings();
  }
}
</script>
<style lang="scss" scoped>
@import "@lazy/_b-card.scss";

.comingsoon-title {
  text-align: left;
  font: normal normal 900 19px Avenir;
  letter-spacing: 0px;
  color: #707070;
  opacity: 1;
}

.text-block {
  font: normal normal 15px Avenir;
  letter-spacing: 0px;
  color: #4D4F5C;
}
</style>
